import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Fetch} from "../../common/lib/api-fetch";
import React, {useEffect, useState} from "react";
import {API_BASE_URL} from "../../constants";
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ButtonBlack} from "../../common/components/color-button";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'black',
    paddingTop: "20px"
  },
  mine: {
    backgroundColor: 'rgb(50,50,50)'
  },
  draw: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  }
}));

const YearPoints = () => {
  const classes = useStyles();
  const [votings, setVotings] = useState(null);
  const [points, setPoints] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [drawResult, setDrawResult] = useState("");

  const fetchPointsData = async (year=null) => {
    await Fetch(`${API_BASE_URL}/voting/points${year ? "/"+year : ""}`, 'GET', {}).then(result => {
      setVotings(result.data);
    })
  }

  useEffect(() => {
    if (votings) {
      let points = {};
      for (let voting of votings) {
        for (let image of voting.images) {
          if (!(image.ownerId in points))
            points[image.ownerId] = {name: image.fullname, score: 0, place: 0, count: 0};
          points[image.ownerId].score += +image.score;
          if (image.score) points[image.ownerId].count++;
        }
      }
      let data = [];
      for (let ownerId of Object.keys(points)) {
        data.push({id: ownerId, ...points[ownerId]});
      }
      data.sort((a, b) => b.score - a.score);
      for (let pos = 0; pos < data.length; pos++) {
        let place = pos + 1;
        if (pos > 0 && data[pos].score === data[pos - 1].score)
          place = data[pos].place;
        data[pos].place = place;
      }
      setPoints(data);
    }
  }, [votings])

  useEffect(() => {
    console.log("Year", year);
    fetchPointsData(year);
  }, [year])

  const updateYear = (year) => {
    setDrawResult("")
    setYear(year.target.value);
  }

  const generateArrayOfYears = () => {
    let max = new Date().getFullYear()
    let min = max - 4
    let years = []

    for (let i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }

  const drawWinner = () => {
    const lots = [];
    for(const point of points) {
      for(let i=0;i<point.count;i++) {
        lots.push(point);
      }
    }
    console.log(lots);
    const winner = Math.floor(Math.random() * lots.length);
    setDrawResult(lots[winner].name);
  }

  return <>
    {points &&
    <Box width={1} className={classes.root}>
      <Container className={classes.root}>
        <div className={"full-height"}>
          <h1 className={"white-text"}>Poeng i årskonkurransen</h1>
          <select name={"year"} value={year} onChange={updateYear}>
            {
              generateArrayOfYears().map(year => <option key={year} value={year}>{year}</option>)
            }
          </select>
          <Row
               className="align-content-center text-center text-white animate fadeIn">
            <Col xs={3} md={1}>
              <span><b>Plassering</b></span>
            </Col>
            <Col xs={3} md={2}>
              <span><b>Navn</b></span>
            </Col>
            <Col xs={3} md={2}>
              <span><b>Poengsum</b></span>
            </Col>
            <Col xs={1} md={1}>
              <span><b>Lodd</b></span>
            </Col>
          </Row>
          {points.map(owner => <Row key={owner.id}
                                    className="align-content-center text-center text-white animate fadeIn">
              <Col xs={3} md={1}>
                <span>{owner.place}. plass</span>
              </Col>
              <Col xs={3} md={2}>
                <span>{owner.name}</span>
              </Col>
              <Col xs={3} md={2}>
                <span>{owner.score?.toFixed(2)}</span>
              </Col>
            <Col xs={1} md={1}>
              <span>{owner.count}</span>
            </Col>
            </Row>
          )}
          <div className={`${classes.draw} mt-5`}><ButtonBlack onClick={() => drawWinner()}>Trekk vinner</ButtonBlack><span className={"ml-3 white-text"}>{drawResult}</span></div>
        </div>
      </Container>
    </Box>
    }
  </>
}

const mapStateToProps = state => ({
  user: state.user.user
});

const connected = connect(mapStateToProps)(YearPoints);

export default withRouter(connected);
