import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Slider from '@material-ui/core/Slider';
import {makeStyles, withStyles} from '@material-ui/core/styles';

import './index.scss';
import {hideHeader, showHeader} from "../header/actions";

import _ from 'lodash';
import {API_BASE_URL} from "../../constants";
import {Fetch} from "../../common/lib/api-fetch";
import Lightbox from "react-image-lightbox";
import {getImageUrl} from "../../common/lib/image";
import {roundNumber} from "../../common/lib/math";
import Container from "@material-ui/core/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ButtonBlack} from "../../common/components/color-button";
import Box from "@material-ui/core/Box";
import VotingBestof from "./VotingBestof";
import Checkbox from "@material-ui/core/Checkbox";
import {Switch} from "@material-ui/core";
import {FlexGap} from "../../common/components/FlexGap";
import Center from "../../common/components/Center";
import styled from 'styled-components';

const VoterSlider = withStyles({
  root: {
    color: 'white',
    height: 8
  },
  valueLabel: {
    '& *': {
      background: '#FFF',
      color: '#000',
    },
  },
})(Slider);

const CheckStyled = withStyles({
  root: {
    color: "white",
  }
})(Checkbox)

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'black'
  },
  input: {
    color: 'white'
  }

}));

const ShowVotingStyled = styled.div`
padding-top: 10px;
  background-color: black;
  .full-height {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  }
`

const SliderStyled = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  .title {
    width: 220px;
    margin-top: 0 !important;
     @media only screen and (min-width: 0px) and (max-width: 767px) {
      width: 100%;
     }
  }
  .number-left {
     padding-right: 7px;
     @media only screen and (min-width: 0px) and (max-width: 767px) {
      display: none;
     }
  }
  .number-right {
    padding-left: 7px;
         @media only screen and (min-width: 0px) and (max-width: 767px) {
      display: none;
     }
  }

  @media only screen and (min-width: 0px) and (max-width: 767px) {
      width: 100vw;
      flex-direction: column;
      padding-left: 5px;
      padding-right: 5px;
  }

`


const Voting = ({hideHeader, showHeader, match, history}) => {
  const [voteData, setVotingData] = useState(null);

  const votingId = match.params.votingId;

  const fetchVotingData = async () => {
    await Fetch(API_BASE_URL + "/voting/" + votingId + "/voteData", 'GET', {}).then(result => {
      setVotingData(result.data);
    })
  };

  useEffect(() => {
    if (votingId)
      fetchVotingData();
  }, [votingId]);

  return (
    <>
      {voteData && voteData.typeId === 4 &&
        <VotingBestof voteData={voteData}/>
      }
      {voteData && (voteData.typeId === 1 || voteData.typeId === 2 || voteData.typeId === 3 || voteData.typeId === 5) &&
        <VotingCategory hideHeader={hideHeader} showHeader={showHeader} match={match} history={history}
                        voteData={voteData}/>
      }
    </>
  )
}

const VotingCategory = ({history, voteData}) => {
  const classes = useStyles();
  const [image, setImage] = useState(0);
  const [currentVote, setCurrentVote] = useState(null);
  const [finished, setFinished] = useState(false);
  const [result, setResult] = useState(null);
  const [lbImages, setLbImages] = useState(null);
  const [showImages, setShowImages] = useState(false);

  const [data, setData] = useState(_.cloneDeep(voteData));

  const categories = useMemo(() => {
    return {
      'wow': {'title': 'Score', min: 1, max: 10, percent: 100},
    }
  }, []);

  const getVoteTemplate = (image) => {
    return {
      wow: 5,
      theme: true,
      imageId: image.id,
      votingId: data.id
    };
  }

  const hasTheme = () => {
    if (!data) return false;
    return data.themePoints > 0;
  }

  useEffect(() => {
    if (data) {
      if (!data.votingImages.some(image => image.answers !== null)) {
        setShowImages(true);
      }
    }
  }, [data])

  useEffect(() => {
    if (data) {
      const left = data.votingImages.filter(image => image.answers === null && !image.mine);
      if (left.length===0)
        setFinished(true);
      else {
        const next = left[Math.floor(Math.random() * left.length)];
        setCurrentVote(getVoteTemplate(next));
        setImage(next);
      }
    }
  }, [data])

  const sendVote = async (vote) => {
    await Fetch(API_BASE_URL + "/voting/vote", "POST", vote).then(result => {
      setData(_.cloneDeep(result.data));
    });
  }

  useEffect(() => {
    if (!finished) return;

    let res = _.cloneDeep(data.votingImages);
    for (let pos = 0; pos < res.length; pos++) {
      const image = res[pos];
      image.result = 0;
      image.theme = false;
      for (let category of Object.keys(categories)) {
        try {
          image.result += data.votingImages[pos].answers[category]
          if (hasTheme())
            image.theme = data.votingImages[pos].answers.theme;
        } catch (err) {

        }
      }
    }
    res = _.sortBy(res, (image) => image.result).reverse();
    setResult(res);
  }, [finished]);

  const doVote = async () => {
    // Send data to server
    await sendVote(currentVote);
  }

  const ShowImages = () => {
    return <div style={{backgroundColor: "black"}}>
      <Center style={{padding: "10px 0 10px 0"}}>
        <ButtonBlack onClick={() => setShowImages(false)}>Gå til stemming</ButtonBlack>
      </Center>
      <FlexGap count={3} gap="1rem">
        {
          data.votingImages.map(image =>
            <div>
              <img className={"vote-result img-fluid shadow"} src={getImageUrl(image)} alt={image.title}
                   onClick={() => setLbImages([getImageUrl(image)])}/>
            </div>
          )
        }
      </FlexGap>
      <Center style={{padding: "10px 0 10px 0"}}>
        <ButtonBlack onClick={() => setShowImages(false)}>Gå til stemming</ButtonBlack>
      </Center>
    </div>
  }

  const ShowResult = () => {
    return <Box width={1} className={classes.root}>
      <Container className={classes.root}>
        {
          result.map(image =>
            <Row key={image.uuid}>
              <Col md={4}>
              </Col>
              <Col md={2} className="m-2">
                <img className={"vote-result img-fluid shadow"} src={getImageUrl(image)} alt={image.title}
                     onClick={() => setLbImages([getImageUrl(image)])}/>
              </Col>
              <Col md={2} className="m-2">
                <div className="white-text">
                  Resultat: {roundNumber(image.result, 0)}
                </div>
                {hasTheme() &&
                  <div className="white-text">Tema: {image.theme ? 'Ja' : 'Nei'}</div>
                }
              </Col>
              <Col md={4}>
              </Col>
            </Row>
          )
        }
        <Row className={"justify-content-center"}>
          <ButtonBlack onClick={() => history.push("/dashboard")}>Avslutt stemming</ButtonBlack>
        </Row>
      </Container>
    </Box>
  }

  const ShowVoting = () => {
    return <ShowVotingStyled className={"voting"}>
      {image && currentVote &&
        <div className={"full-height"}>
          <h5 className={"title white-text"}>Tittel: {image.title}</h5>
          {data?.theme &&
            <h5 className="title white-text">Tema: {data.theme}</h5>
          }
          <img className={"vote"} src={getImageUrl(image)}
               alt={image.title}
               onClick={() => setLbImages([getImageUrl(image)])}/>
          <SliderStyled>
            <div className={"white-text mt-2 title"}>{categories.wow.title}</div>
            <span className="font-weight-bold white-text number-left">min {categories.wow.min}</span>
            <VoterSlider
              min={categories.wow.min}
              max={categories.wow.max}
              defaultValue={currentVote.wow}
              onChangeCommitted={(event, v) => setCurrentVote({...currentVote, wow: v})}
              getAriaValueText={(value) => value}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
            />
            <span className="font-weight-bold white-text number-right">{categories.wow.max} maks</span>
          </SliderStyled>
          {hasTheme() &&
            <Row center>
              <span className={"white-text pt-2"}>Tema</span>
              <CheckStyled
                checked={currentVote.theme}
                onClick={(event) => setCurrentVote({...currentVote, theme: !currentVote.theme})}
              />
            </Row>
          }
          <Row center>
            <ButtonBlack color={"dark"} className={"white-text"}
                         onClick={() => doVote(currentVote)}>Stem: {currentVote && `${currentVote.wow}`} </ButtonBlack>
            <button type="button" className={"white-text btn btn-sm"} onClick={() => history.push("/dashboard")}>Pause

            </button>
          </Row>
        </div>
      }
    </ShowVotingStyled>
  }

  return <>
    {result !== null && !showImages && <ShowResult/>}
    {result === null && !showImages && image && <ShowVoting/>}
    {showImages && <ShowImages/>}
    {lbImages !== null && (
      <Lightbox
        mainSrc={lbImages[0]}
        onCloseRequest={() => setLbImages(null)}
      />
    )}
  </>
};

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {hideHeader, showHeader})(Voting);

export default withRouter(connected);
